import { hooksFetch, hooksFetchPost, hooksFetchPatch } from '../core/api'

export const useAdsApi = () => ({
  getAnnonces: async (params) => {
    return await hooksFetch('/v2/real-estate-ads', { params })
  },
  getAnnounceById: async (id) => {
    return await hooksFetch(`/v2/real-estate-ads/${id}`)
  },
  updateOfferById: async (id, body) => {
    return await hooksFetchPatch(`/v2/real-estate-ads/${id}`, { body })
  },
  sendAdContactForm: async (id, body) => {
    return await hooksFetchPost(`/v1/real-estate-ads/${id}/contact`, { body })
  },
  getAdPhone: async (id, body) => {
    return await hooksFetchPost(`/v1/real-estate-ads/${id}/phone`, { body })
  },
  searchTags: async (query) => {
    return await hooksFetch('/v2/real-estate-ads/tags', { query: { filter: query } })
  },
})
